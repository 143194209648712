<template>
  <div class="VIPcenter">
    <div class="bannerA" v-if="$route.path != '/details'">
      <img class="banimg" :src="require('assets/images/VIPcenter/bgc1.png')" />

      <div
        class="btn"
        @click="
          $router.push({
            name: 'details',
            params: {
              id: 1,
            },
          })
        "
      >
        立即加入
      </div>
    </div>

    <div class="bannerB">
      <div class="title">选择适合您的会员套餐</div>

      <div class="vip_box">
        <div class="vip_item" v-for="(item, index) in VIPinfo" :key="index">
          <img class="icon" :src="item.img" alt="" />
          <div class="title">{{ item.name }}</div>
          <div class="price">
            <span class="rmb" v-if="index != 0">¥</span>
            <span class="price_text">
              {{ item.price }}
            </span>
            <span class="text" v-if="index != 0">月起</span>
          </div>
          <div class="text">
            {{ index != 0 ? "按人民币2年付计费" : "&nbsp;" }}
          </div>

          <div class="child_box" :class="isunfold ? 'highly' : ''">
            <div class="child_item" v-for="(v, i) in item.child" :key="i">
              <div class="dian"></div>
              <div class="name">{{ v }}</div>
            </div>
          </div>
          <!-- @click="isunfold = !isunfold" -->
          <!-- <div class="unfold">
            <div class="text" v-if="item.unfold">
              {{ isunfold ? "收起" : "查看更多" }}
              <img
                class="unfoldimg"
                :class="isunfold ? 'rotating' : ''"
                :src="require('assets/images/VIPcenter/unfold.png')"
                alt=""
              />
            </div>
            <div class="text" v-else>&nbsp;</div>
          </div> -->

          <!-- <div
            class="btn_box"
            @click="buyVip(item)"
            :class="{ hide: !item.unfold && isLogin }"
          >
            {{ item.unfold ? "立即加入" : "免费注册" }}
          </div> -->
        </div>
      </div>
    </div>

    <div class="bannerD">
      <div class="VIPcontrast">
        <div class="vip_title">会员服务权益对比</div>
        <div class="title">
          <div class="title_item" v-for="item in titlelist" :key="item">
            {{ item }}
          </div>
        </div>

        <div class="introduce">
          <div class="name_box" v-for="(v, i) in introducelist" :key="i">
            <div
              class="name"
              :class="[
                v.special ? 'w' : '',
                v.title == '智能推文' ||
                v.title == '文库' ||
                v.title == '金句' ||
                v.title == '模板'
                  ? 'rl'
                  : '',
              ]"
            >
              {{ v.title }}
            </div>

            <div class="name_item_box">
              <div class="item" v-for="(v1, i1) in v.child" :key="i1">
                <div class="name" v-if="v1.name">
                  {{ v1.name }}
                </div>

                <div class="vip_box">
                  <div
                    class="vip_item"
                    v-for="(v1, i2) in v1.child"
                    :key="i2"
                    :class="typeof v1 == 'string' ? 'str' : ''"
                  >
                    <img
                      v-show="
                        v1 ? (typeof v1 == 'string' ? false : true) : false
                      "
                      :src="require('assets/images/VIPcenter/have.png')"
                      alt=""
                    />

                    {{ v1 ? (typeof v1 == "string" ? v1 : "") : "-" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bannerC">
      <commonFooter />
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import commonFooter from "components/footer/commonFooter.vue";
import { getToken } from "utils/auth.js";
export default {
  name: "VIPcenter",
  //import引入的组件需要注入到对象中才能使用
  components: { commonFooter },
  data() {
    //这里存放数据
    return {
      isLogin: true,
      isunfold: false,
      // titlelist: ["功能", "普通会员", "VIP会员", "SVIP会员", "PLUS会员"],
      titlelist: ["功能", "普通会员", "VIP会员", "SVIP会员"],
      introducelist: [
        {
          title: "智能推文",
          child: [
            {
              name: "智能网盘/个人作品集",
              child: [true, true, true],
            },
            {
              name: "通用类语境算法引擎",
              child: [true, true, true],
            },
            {
              name: "学科类语境算法引擎",
              child: [false, true, true],
            },
            {
              name: "职业类语境算法引擎",
              child: [false, true, true],
            },
            {
              name: "专业类语境算法引擎",
              child: [false, false, true],
            },
            {
              name: "主题类语境算法引擎",
              child: [false, false, true],
            },
          ],
        },
        {
          title: "AI绘画",
          special: true,
          child: [
            {
              name: "",
              child: ["成语券支付", "成语券支付", "成语券支付"],
            },
          ],
        },
        {
          title: "截图取字",
          special: true,
          child: [
            {},
            {
              name: "",
              child: ["试用30次", true, true],
            },
          ],
        },
        {
          title: "智能网盘",
          special: true,
          child: [
            {
              name: "",
              child: ["1G", "20G", "50G"],
            },
          ],
        },
        {
          title: "文库",
          child: [
            {
              name: "领导人时事新闻",
              child: [true, true, true],
            },
            {
              name: "重大会议",
              child: [false, true, true],
            },
            {
              name: "新闻联播",
              child: [false, true, true],
            },
            {
              name: "新闻发布",
              child: [false, true, true],
            },
            {
              name: "党内法规",
              child: [false, true, true],
            },
            {
              name: "法律法规",
              child: [false, true, true],
            },

            {
              name: "工作报告",
              child: [false, true, true],
            },
            {
              name: "战略规划",
              child: [false, false, true],
            },
            {
              name: "政策文件",
              child: [false, false, true],
            },

            {
              name: "裁判文书",
              child: [false, false, true],
            },
            {
              name: "执法文书",
              child: [false, false, true],
            },
            {
              name: "出版图书",
              child: ["第三方版权作品", "第三方版权作品", "第三方版权作品"],
            },
            {
              name: "文字作品",
              child: ["第三方版权作品", "第三方版权作品", "第三方版权作品"],
            },

            {
              name: "学位论文",
              child: ["第三方版权作品", "第三方版权作品", "第三方版权作品"],
            },
            {
              name: "学术论文",
              child: ["第三方版权作品", "第三方版权作品", "第三方版权作品"],
            },
          ],
        },
        {
          title: "模板",
          child: [
            {
              name: "公文写作",
              child: [false, true, true],
            },
            {
              name: "基层党建",
              child: [false, true, true],
            },
            {
              name: "个人文稿",
              child: [false, true, true],
            },
            {
              name: "法律文书",
              child: [false, true, true],
            },
            {
              name: "合同协议",
              child: [false, true, true],
            },
            {
              name: "机关制度",
              child: [false, true, true],
            },
            {
              name: "活动策划",
              child: [false, true, true],
            },
            {
              name: "节日庆典",
              child: [false, true, true],
            },
            {
              name: "大学生类",
              child: [false, true, true],
            },
            {
              name: "教师专用",
              child: [false, true, true],
            },
            {
              name: "文秘专用",
              child: [false, true, true],
            },
            {
              name: "公司管理",
              child: [false, false, true],
            },
            {
              name: "企业制度",
              child: [false, false, true],
            },
            {
              name: "商业计划书",
              child: [false, false, true],
            },
          ],
        },
        // {
        //   title: "溯源",
        //   child: [
        //     {},
        //     {
        //       name: "字符包",
        //       child: [false, "1万/月", "2万/月"],
        //     },
        //   ],
        // },

        {
          title: "金句",
          child: [
            {},
            {
              name: "核心金句",
              child: [true, true, true],
            },
            {
              name: "毛主席诗词名言",
              child: [true, true, true],
            },
            {
              name: "公文写作",
              child: [false, true, true],
            },
            {
              name: "儒家经典",
              child: [false, true, true],
            },
            {
              name: "道家经典",
              child: [false, true, true],
            },
            {
              name: "百家经典",
              child: [false, true, true],
            },
            {
              name: "法家经典",
              child: [false, true, true],
            },
            {
              name: "兵家经典",
              child: [false, true, true],
            },
            {
              name: "佛经经典",
              child: [false, true, true],
            },
            {
              name: "中医典籍",
              child: [false, true, true],
            },
            {
              name: "楚辞经典",
              child: [false, true, true],
            },
            {
              name: "汉赋经典",
              child: [false, true, true],
            },
            {
              name: "汉乐府经典",
              child: [false, true, true],
            },
            {
              name: "史书传记",
              child: [false, true, true],
            },
            {
              name: "建安诗歌",
              child: [false, true, true],
            },
            {
              name: "魏晋南北朝民歌",
              child: [false, true, true],
            },
            {
              name: "唐诗经典",
              child: [false, true, true],
            },
            {
              name: "宋词经典",
              child: [false, true, true],
            },
            {
              name: "元曲经典",
              child: [false, true, true],
            },
            {
              name: "明代经典",
              child: [false, true, true],
            },
            {
              name: "清代经典",
              child: [false, true, true],
            },
            {
              name: "古典小说",
              child: [false, true, true],
            },
            {
              name: "中华名著",
              child: [false, true, true],
            },
            {
              name: "小说经典",
              child: [false, true, true],
            },
            {
              name: "小学作文",
              child: [false, true, true],
            },
            {
              name: "初中作文",
              child: [false, true, true],
            },
            {
              name: "中考作文",
              child: [false, true, true],
            },
            {
              name: "高中作文",
              child: [false, true, true],
            },
            {
              name: "高考作文",
              child: [false, true, true],
            },
          ],
        },

        // {
        //   title: "核校",
        //   special: true,
        //   child: [
        //     {
        //       name: "",
        //       child: [true, true, true],
        //     },
        //   ],
        // },

        // {
        //   title: "数据分析",
        //   special: true,
        //   child: [
        //     {},
        //     {
        //       name: "",
        //       child: [true, true, true],
        //     },
        //   ],
        // },
        // {
        //   title: "网页采集",
        //   special: true,
        //   child: [
        //     {
        //       name: "",
        //       child: ["免费10次", true, true],
        //     },
        //   ],
        // },

        // {
        //   title: "文件OCR",
        //   special: true,
        //   child: [
        //     {},
        //     {
        //       name: "",
        //       child: ["免费10次", true, true],
        //     },
        //   ],
        // },
      ],
      VIPinfo: [
        {
          name: "普通会员",
          price: "免费",
          img: require("assets/images/VIPcenter/vip1.png"),
          unfold: false,
          diskSize: "1G",
          child: [
            "1G智能网盘空间",
            "可使用“智能推文”中通用中文、核心、著作作品等3个通用类语境算法引擎",
            "可使用“文库”中领导人时事新闻",
            "可使用“核校”自定义规则功能",
            "可使用“金句”中核心金句、毛主席诗词名言",
            "可使用数据分析、网页采集、截图OCR（免费30次）功能",
            "可建立个人作品集，上传付费著作作品，提现版权作品字符收益、出售收益、授权收益",
          ],
        },
        {
          name: "VIP会员",
          price: "7.8",
          img: require("assets/images/VIPcenter/vip2.png"),
          isunfold: false,
          unfold: true,
          diskSize: "20G",
          CHbag: "1万/月",
          child: [
            "20G智能网盘空间",
            "可使用“智能推文”中通用类、学科类、职业类等三大类语境算法引擎",
            "可使用“文库”中领导人时事新闻、重大会议、新闻联播、新闻发布、党内法规、法规法则、工作报告7个文库版块",
            "可使用“核校”中全部功能",
            "可使用“金句”中全部类目",
            "可使用“模板”中不包含公司管理、企业制度、商业计划书的其他全部类目",
            "可使用数据分析、网页采集、截图OCR功能",
            "可建立个人作品集，上传付费著作作品，提现版权作品字符收益、出售收益、授权收益",
          ],
        },
        {
          name: "SVIP会员",
          price: "15.8",
          img: require("assets/images/VIPcenter/vip3.png"),
          unfold: true,
          isunfold: false,
          diskSize: "50G",
          CHbag: "2万/月",
          child: [
            "50G智能网盘空间",
            "可使用“智能推文”中通用类、学科类、职业类、专业类、主题类等五大类语境算法引擎",
            "可使用“文库”中除第三方版权外全部类目",
            "可使用“核校”中全部功能",
            "可使用“金句”中全部类目",
            "可使用“模板”中除第三方版权外全部类目模板",
            "可使用数据分析、网页采集、截图OCR功能",
            "可建立个人作品集，上传付费著作作品，提现版权作品字符收益、出售收益、授权收益",
          ],
        },
        // {
        //   name: "PLUS会员",
        //   price: "9.7",
        //   img: require("assets/images/VIPcenter/vip4.png"),
        //   unfold: true,
        //   isunfold: false,
        //   diskSize: "100G",
        //   CHbag: "3万/月",
        //   child: [
        //     "100G智能网盘空间",
        //     "可使用“智能推文”中通用类、学科类、职业类、专业类、主题类等五大类语境算法引擎",
        //     "可使用“文库”中全部类目",
        //     "可使用“核校”中全部功能",
        //     "可使用“金句”中全部类目",
        //     "可使用“模板”中全部类目",
        //     "可使用数据分析、网页采集、截图OCR功能",
        //     "可建立个人作品集，上传付费著作作品，提现版权作品字符收益、出售收益、授权收益",
        //   ],
        // },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    buyVip(item) {
      if (item.unfold) {
        let type = 0;
        switch (item.name) {
          case "VIP会员":
            type = 1;
            break;
          case "SVIP会员":
            type = 2;
            break;
          case "PLUS会员":
            type = 3;
            break;
        }
        this.$router.push({
          name: "details",
          params: {
            id: 1,
            type,
          },
          query: {},
        });
      } else {
        window.registerFun();
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (!getToken()) {
      this.isLogin = false;
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>

<style lang="scss" scoped>
.VIPcenter {
  width: 100%;
  position: absolute;
  left: 0;
  background-color: #fbf8fb;
  min-width: 1300px;
  .bannerA {
    width: 100%;

    position: relative;
    margin-top: -30px;

    .banimg {
      width: 100%;
      // height: 100%;
    }

    .btn {
      cursor: pointer;
      position: absolute;
      line-height: 44.3px;
      bottom: 38px;
      left: 50%;
      transform: translate(-50%, 0);
      text-align: center;
      width: 139.4px;
      height: 44.3px;
      font-size: 18px;
      color: #fff;
      background: url("~assets/images/VIPcenter/btn.png");
      background-size: 100% 100%;
    }
  }

  .bannerB {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // padding: 93px 207px 150px;
    width: 1500px;
    min-width: 1300px;
    margin: 100px auto;
    .title {
      font-size: 34px;
      font-weight: bold;
    }
    .vip_box {
      margin-top: 90px;
      width: 90%;
      display: flex;
      justify-content: space-between;

      align-items: flex-start;
      .vip_item {
        background-color: #fffeff;
        width: 24%;
        position: relative;
        padding: 0 30px;
        box-sizing: border-box;
        text-align: center;
        box-shadow: 0px 0px 15px 1px rgba(98, 102, 107, 0.09);

        .icon {
          position: absolute;
          left: 0;
          top: -20px;
          width: 80px;
        }

        .title {
          text-align: center;
          margin: 33px 0 0;
          font-size: 28px;
          font-weight: bold;
        }

        .price {
          display: inline-block;
          position: relative;
          margin-top: 35px;

          .price_text {
            font-size: 50px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #000000;
            background: linear-gradient(0deg, #d8a07e 0%, #ffd5b5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .rmb {
            position: absolute;
            top: 5px;
            left: -10px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }

          .text {
            position: absolute;
            bottom: 0;
            right: -30px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }

        .text {
          text-align: center;
          margin-top: 20px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #a1a1a1;
        }

        .child_box {
          margin-top: 37px;
          overflow: hidden;
          height: 720px;
          .child_item {
            position: relative;
            .bold {
              font-weight: bold;
            }
            .dian {
              position: absolute;
              top: 10px;
              left: 0px;
              width: 6px;
              height: 6px;
              background: #d8a07e;
              border-radius: 50%;
            }

            .name {
              font-size: 14px;
              padding-left: 20px;
              line-height: 28px;
              text-align: left;
              margin-bottom: 25px;
            }
          }
        }
        .highly {
          height: 3100px;
        }
        .unfold {
          margin-top: 5px;
          .text {
            color: #d8a07e;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            .unfoldimg {
              margin-left: 10px;
              width: 11px;
            }

            .rotating {
              transform: rotate(180deg);
            }
          }
        }
        .hide {
          visibility: hidden;
        }
        .btn_box {
          cursor: pointer;
          margin: 40px auto;
          width: 126px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          font-size: 14px;
          background: linear-gradient(90deg, #d8a07e, #ffd5b5);
          border-radius: 10px;
          color: #fff;
        }
      }
    }
  }

  .bannerC {
    width: 100%;
  }

  .bannerD {
    padding: 0 0 120px 0;
    background-color: #ffede1;
    display: flex;
    justify-content: center;

    .VIPcontrast {
      .vip_title {
        margin: 80px 0;
        text-align: center;
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }

      .title {
        display: flex;
        align-items: center;
        .title_item {
          text-align: center;
          padding: 25px 0;
          font-size: 20px;
          font-family: PingFang SC;
          width: 206px;
          background-color: #fff;
          font-weight: bold;

          &:nth-child(1) {
            width: 515px;
          }
          &:nth-child(2) {
            background: #f5f5f5;
          }
          &:nth-child(3) {
            background: #f9e3d4;
          }
          &:nth-child(4) {
            background: #f8cfb3;
          }
          &:nth-child(5) {
            background: #f0c1a1;
          }
        }
      }
      .introduce {
        .name_box {
          margin: 3px 0;
          display: flex;
          .name {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 82px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            background-color: #fff;
            margin-right: 3px;
          }

          .w {
            width: 515px;
            margin-right: 0;
          }

          .rl {
            // writing-mode: vertical-rl;
          }

          .name_item_box {
            .item {
              display: flex;
              align-items: center;

              .name {
                width: 430px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                margin-right: 0px;
              }

              &:nth-child(2n - 1) .name {
                background-color: #f9f4f4;
              }

              .vip_box {
                display: flex;
                align-items: center;

                .vip_item {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 50px;
                  width: 206px;
                  font-weight: bold;
                  font-size: 30px;
                  color: #fff;

                  img {
                    width: 16px;
                  }

                  &:nth-child(1) {
                    background-color: #edebe9;
                  }
                  &:nth-child(2) {
                    background-color: #f5dbc9;
                  }
                  &:nth-child(3) {
                    background-color: #f5c6a6;
                  }
                  &:nth-child(4) {
                    background-color: #e6b18c;
                  }
                }

                .str {
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #9e6c49;
                }
              }

              &:nth-child(2n) .vip_box .vip_item {
                &:nth-child(1) {
                  background-color: #f5f5f5;
                }
                &:nth-child(2) {
                  background-color: #f9e3d4;
                }
                &:nth-child(3) {
                  background-color: #f8cfb4;
                }
                &:nth-child(4) {
                  background-color: #f0c1a1;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
