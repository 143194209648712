var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"VIPcenter"},[(_vm.$route.path != '/details')?_c('div',{staticClass:"bannerA"},[_c('img',{staticClass:"banimg",attrs:{"src":require('assets/images/VIPcenter/bgc1.png')}}),_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
          name: 'details',
          params: {
            id: 1,
          },
        })}}},[_vm._v(" 立即加入 ")])]):_vm._e(),_c('div',{staticClass:"bannerB"},[_c('div',{staticClass:"title"},[_vm._v("选择适合您的会员套餐")]),_c('div',{staticClass:"vip_box"},_vm._l((_vm.VIPinfo),function(item,index){return _c('div',{key:index,staticClass:"vip_item"},[_c('img',{staticClass:"icon",attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"price"},[(index != 0)?_c('span',{staticClass:"rmb"},[_vm._v("¥")]):_vm._e(),_c('span',{staticClass:"price_text"},[_vm._v(" "+_vm._s(item.price)+" ")]),(index != 0)?_c('span',{staticClass:"text"},[_vm._v("月起")]):_vm._e()]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(index != 0 ? "按人民币2年付计费" : " ")+" ")]),_c('div',{staticClass:"child_box",class:_vm.isunfold ? 'highly' : ''},_vm._l((item.child),function(v,i){return _c('div',{key:i,staticClass:"child_item"},[_c('div',{staticClass:"dian"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(v))])])}),0)])}),0)]),_c('div',{staticClass:"bannerD"},[_c('div',{staticClass:"VIPcontrast"},[_c('div',{staticClass:"vip_title"},[_vm._v("会员服务权益对比")]),_c('div',{staticClass:"title"},_vm._l((_vm.titlelist),function(item){return _c('div',{key:item,staticClass:"title_item"},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"introduce"},_vm._l((_vm.introducelist),function(v,i){return _c('div',{key:i,staticClass:"name_box"},[_c('div',{staticClass:"name",class:[
              v.special ? 'w' : '',
              v.title == '智能推文' ||
              v.title == '文库' ||
              v.title == '金句' ||
              v.title == '模板'
                ? 'rl'
                : '' ]},[_vm._v(" "+_vm._s(v.title)+" ")]),_c('div',{staticClass:"name_item_box"},_vm._l((v.child),function(v1,i1){return _c('div',{key:i1,staticClass:"item"},[(v1.name)?_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(v1.name)+" ")]):_vm._e(),_c('div',{staticClass:"vip_box"},_vm._l((v1.child),function(v1,i2){return _c('div',{key:i2,staticClass:"vip_item",class:typeof v1 == 'string' ? 'str' : ''},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
                      v1 ? (typeof v1 == 'string' ? false : true) : false
                    ),expression:"\n                      v1 ? (typeof v1 == 'string' ? false : true) : false\n                    "}],attrs:{"src":require('assets/images/VIPcenter/have.png'),"alt":""}}),_vm._v(" "+_vm._s(v1 ? (typeof v1 == "string" ? v1 : "") : "-")+" ")])}),0)])}),0)])}),0)])]),_c('div',{staticClass:"bannerC"},[_c('commonFooter')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }